import { withStyles } from "@material-ui/core/styles";
import { formatDate } from "app/main/utils/dateUtils";
import { Typography, Icon, IconButton } from "@material-ui/core";

import {
	TimelineItem,
	Timeline,
	TimelineSeparator,
	TimelineDot,
	TimelineConnector,
	TimelineContent,
} from "@material-ui/lab";

const StyledTimelineItem = withStyles({
	missingOppositeContent: {
		"&:before": {
			display: "none",
		},
		minHeight: "30px",
	},
})(TimelineItem);

function LoadSearchViewLocationsView({ load, onEditRoute, showAllStops, hideEdit, hideOrigin }) {
	return (
		<Timeline className="-ml-14 -mb-20 -mt-6">
			{!hideOrigin && (
				<StyledTimelineItem>
					<TimelineSeparator>
						<TimelineDot color="primary" className="py-5 px-5">
							<Icon className={"text-20 font-500 "}>{"local_shipping"}</Icon>
						</TimelineDot>
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						<Typography className="text-12 ml:text-13 break-normal mt-10 font-semibold">
							{load?.truckOrigin ?? "Origin"}
						</Typography>
						<Typography className="text-12 ml:text-13 mt-20 mb-8 font-semibold">
							{load.emptyMiles ? load.emptyMiles : load?.distance_to_origin} mi
						</Typography>
					</TimelineContent>
				</StyledTimelineItem>
			)}
			<StyledTimelineItem>
				<TimelineSeparator>
					<TimelineDot color="secondary" className="py-5 px-5">
						<Icon className={"text-20 font-500 "}>{"storefront"}</Icon>
					</TimelineDot>
					<TimelineConnector />
				</TimelineSeparator>
				<TimelineContent>
					<div className="flex flex-row items-center">
						<div className="flex flex-col">
							<Typography className="text-12 ml:text-13 break-normal font-semibold">{load?.origin}</Typography>
							<Typography className="text-12 ml:text-13 break-normal text-grey-800">
								{formatDate(load?.ready, load?.pickupby, true, false)}
							</Typography>
						</div>
						{!hideEdit && !load?.deleted && (
							<IconButton
								aria-label="edit"
								className="ml-10"
								size="small"
								color="inherit"
								onClick={() => onEditRoute?.("PICKUP")}
							>
								<Icon className={"text-20 text-black font-500 m-3"}>{"edit"}</Icon>
							</IconButton>
						)}
					</div>
					{(!showAllStops || (load?.locations?.length ?? 0) < 1) && (
						<div className="flex flex-row items-center mt-20 mb-8 ">
							<Typography className="text-12 ml:text-13 font-semibold">{load?.miles} mi</Typography>
							{load?.stopsNumber > 2 && (
								<Typography className="text-12 ml:text-13 ml-10">{load?.stopsNumber - 2} extra stop(s)</Typography>
							)}
						</div>
					)}
					{showAllStops && load?.locations?.length > 0 && load?.originMiles && (
						<div className="flex flex-row items-center mt-20 mb-8 ">
							<Typography className="text-12 ml:text-13 font-semibold">{load?.originMiles} mi</Typography>
						</div>
					)}
				</TimelineContent>
			</StyledTimelineItem>
			{showAllStops &&
				load?.locations?.map((stop, k) => {
					return (
						<StyledTimelineItem key={k}>
							<TimelineSeparator>
								<TimelineDot color="secondary" className="py-5 px-5">
									<Icon className={"text-20 font-500 "}>{"add_location"}</Icon>
								</TimelineDot>
								<TimelineConnector />
							</TimelineSeparator>
							<TimelineContent>
								<div className="flex flex-row items-center">
									<div className="flex flex-col">
										<Typography className="text-12 ml:text-13 break-norma font-bold">{stop?.location}</Typography>
										<Typography className="text-12 ml:text-13 break-normal text-grey-800">
											{formatDate(stop?.location_date, stop?.location_window_date, true, false)}
										</Typography>
									</div>
									{!hideEdit && !load?.deleted && (
										<IconButton
											aria-label="edit"
											className="ml-10"
											size="small"
											color="inherit"
											onClick={() => onEditRoute?.("STOP")}
										>
											<Icon className={"text-20 text-black font-500 m-3"}>{"edit"}</Icon>
										</IconButton>
									)}
								</div>
								{load.locations.length === k + 1 && !stop.miles && (
									<div className="flex flex-row items-center mt-20 mb-8 ">
										<Typography className="text-12 ml:text-13 font-semibold">{load?.miles} mi</Typography>
										{load?.stopsNumber > 2 && (
											<Typography className="text-12 ml:text-13 ml-10">
												{load?.stopsNumber - 2} extra stop(s)
											</Typography>
										)}
									</div>
								)}
								{stop.miles && (
									<div className="flex flex-row items-center mt-20 mb-8 ">
										<Typography className="text-12 ml:text-13 font-semibold">{stop.miles} mi</Typography>
									</div>
								)}
							</TimelineContent>
						</StyledTimelineItem>
					);
				})}
			<StyledTimelineItem>
				<TimelineSeparator>
					<TimelineDot color="secondary" className="py-5 px-5">
						<Icon className={"text-20 font-500 "}>{"location_on"}</Icon>
					</TimelineDot>
					{load?.distance_to_dest > 0 && <TimelineConnector />}
				</TimelineSeparator>
				<TimelineContent>
					<div className="flex flex-row items-center">
						<div className="flex flex-col">
							<Typography className="text-12 ml:text-13 break-norma font-bold">{load?.destination}</Typography>
							<Typography className="text-12 ml:text-13 break-normal text-grey-800">
								{formatDate(load?.dropready, load?.deliverby, true, false)}
							</Typography>
						</div>
						{!hideEdit && !load?.deleted && (
							<IconButton
								aria-label="edit"
								className="ml-10"
								size="small"
								color="inherit"
								onClick={() => onEditRoute?.("DELIVERY")}
							>
								<Icon className={"text-20 text-black font-500 m-3"}>{"edit"}</Icon>
							</IconButton>
						)}
					</div>
					{load?.distance_to_dest > 0 && (
						<Typography className="text-12 ml:text-13 mt-20 mb-8 font-semibold">
							{load?.distance_to_dest} miles
						</Typography>
					)}
				</TimelineContent>
			</StyledTimelineItem>
			{load?.distance_to_dest > 0 && (
				<StyledTimelineItem>
					<TimelineSeparator>
						<TimelineDot color="primary" className="py-5 px-5">
							<Icon className={"text-20 font-500 "}>{"flag"}</Icon>
						</TimelineDot>
					</TimelineSeparator>
					<TimelineContent>
						<Typography className="text-12 ml:text-13 break-normal mt-10 font-semibold">
							{load?.truckDestination ?? "Destination"}
						</Typography>
					</TimelineContent>
				</StyledTimelineItem>
			)}
		</Timeline>
	);
}

export default LoadSearchViewLocationsView;
