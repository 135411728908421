import * as clipboard from "clipboard-polyfill";
// Store
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { openFormDialog } from "app/store/tools/formDialogSlice";
import { updateLoadData } from "app/store/search/searchV3Slice";

// Services
import { isDriver, isRoleExternal } from "app/services/LoginService";
import { executeLoadAction, saveFavoriteLoad } from "app/services/searchServices";
import { geturl } from "app/services/tripsServices";
import { isEnabled } from "app/services/featureStorageService";

// Utils
import { hideRate } from "app/main/utils/tripsUtils";
import { showSnackbar, showErrorSnackbar } from "app/main/utils/snackbarUtil";
import { setLoad } from "app/store/loads/loadsSlice";
import { hasRequiredGateKeepers, roleRestrictions } from "app/main/utils/rolesUtils";
import store from "app/store";
// Components
import { convertFieldsToText, handledActionMessage } from "../config/suggestUtils";

// Const
import { RATE_OPTIONS } from "app/main/consts";
import { getDistanceMaps } from "app/main/utils/calculatorUtils";

const ERROR_CALL_BROKER = "This broker does not currently have a contact number";

const COPY_FIELDS = [
	{ accessor: "truckName", label: "For", variant: "join", variantValue: "carrierName" },
	{
		accessor: "clientid",
		label: "Broker - MC Number",
		variant: "join",
		variantValue: "mcnumber",
	},
	{ accessor: "truckOrigin", label: "Empty" },
	{ accessor: "origin", label: "PU", variant: "stop", variantValue: "pickupby" },
	{ accessor: "locations", variant: "locations" },
	{ accessor: "destination", label: "DEL", variant: "stop", variantValue: "deliverby" },
	{ accessor: "distance_to_origin", label: "DH-O" },
	{ accessor: "distance_to_dest", label: "DH-D" },
	{ accessor: "miles", label: "Loaded miles" },
	{ accessor: "total_miles", label: "Total miles" },
	{ accessor: "price", label: "Rate", variant: "price" },
	{ accessor: "rpm", label: "RPM (DH-O + Loaded)", variant: "price" },
	{ accessor: "weight", label: "Weight", variant: "weight" },
	{ accessor: "specinfo", label: "Special Notes" },
];

let latestLoad = {};

const getButtons = (dataIds, l, snackbarMsg, dispatch, smallScreen, setDialog, executeAction, user) => {
	latestLoad = l;
	const isUserExternal = isRoleExternal();
	const isUserDriver = isDriver();

	const copyToClipboardOrSendNativeMsg = (message, clipboardItem = null) => {
		if (window?.ReactNativeWebView) {
			// TODO: [PATRICIO] Switch type to COPY_TO_CLIPBOARD here and hopcopilot
			window?.ReactNativeWebView?.postMessage(
				JSON.stringify({
					type: "COPY_SMS",
					data: { message },
				})
			);
			snackbarMsg("Copied", "success");
		} else {
			const clipboardFunc = clipboardItem ? clipboard.write : clipboard.writeText;
			clipboardFunc(clipboardItem ? [clipboardItem] : message).then(
				() => snackbarMsg("Copied", "success"),
				() => snackbarMsg("Failed copy", "error")
			);
		}
		//eslint-disable-next-line
	};

	const handleCopySmsOption = () => {
		const loadData = {
			...latestLoad,
		};
		loadData.empty_miles = (loadData?.distance_to_origin ?? 0) + (loadData?.distance_to_dest ?? 0);
		loadData.total_miles = (loadData?.miles ?? 0) + loadData.empty_miles;

		let textToCopy = convertFieldsToText(loadData, COPY_FIELDS);
		textToCopy = textToCopy.join("\n");

		copyToClipboardOrSendNativeMsg(textToCopy);
	};

	const onClickPin = (form, type, option = null) => {
		try {
			const requestData = {
				loadData: latestLoad,
				type: type,
				form: form,
				searchData: {
					...dataIds,
				},
			};
			saveFavoriteLoad(dataIds?.userId, dataIds?.searchId, requestData).then(
				(res) => {
					if (type !== "SHARED") {
						//triggersOnCloseRef.current.pinnedLoads = true;
						snackbarMsg("Saved", "success");
						if (type === "LIKED") {
							dispatch(incrementDataRevision({ event: "pinnedLoadsRevision" }));
						}
						return;
					}

					const {
						carrier__view,
						age,
						available,
						carrier,
						truckName,
						primaryDriverName,
						primaryDriver,
						carrierMcNumber,
						carrierName,
						city,
						clientid,
						contact,
						coveredBy,
						deleted,
						destination,
						destination__view,
						dh_verified,
						distance_to_dest,
						distance_to_origin,
						dropready,
						duration,
						ecity,
						equipment,
						est_bid_price,
						est_fw_price,
						est_price,
						load_id,
						locations,
						all_locations,
						mcnumber,
						miles,
						origin,
						origin__view,
						owner,
						pickupby,
						price,
						ready,
						rpm,
						stopsNumber,
						total_miles,
						trailerSize,
						tripid,
						truck,
						url,
						weight,
						truckOrigin,
						truckDestination,
					} = { ...(dataIds?.load ?? {}), ...(latestLoad ?? {}) };

					const data = {
						load: {
							carrier__view,
							truckName,
							primaryDriverName,
							primaryDriver,
							age,
							available,
							carrier,
							carrierMcNumber,
							carrierName,
							city,
							clientid,
							contact,
							coveredBy,
							deleted,
							destination,
							destination__view,
							dh_verified,
							distance_to_dest,
							distance_to_origin,
							dropready,
							duration,
							ecity,
							equipment,
							est_bid_price,
							est_fw_price,
							est_price,
							load_id,
							locations,
							all_locations,
							mcnumber,
							miles,
							origin,
							origin__view,
							owner,
							pickupby,
							price,
							ready,
							rpm,
							stopsNumber,
							total_miles,
							trailerSize,
							tripid,
							truck,
							url,
							weight,
							truckOrigin,
							truckDestination,
						},
						searchId: dataIds?.searchId,
						userId: dataIds?.userId,
						loadId: dataIds?.loadId,
						truckId: dataIds?.truckId,
						carrierId: dataIds?.carrierId,
						viewId: form,
						mode: "VIEW",
						hideRate: option,
					};
					geturl(data).then(
						(res) => copyToClipboardOrSendNativeMsg(res.url),
						(error) => {
							snackbarMsg(error.errors?.[0]?.message ?? "Failed to generate url", "error");
						}
					);
				},
				({ errors }) => snackbarMsg(errors[0].message, "error")
			);
		} catch (error) {
			snackbarMsg(error.errors?.[0]?.message ?? "Opps, operation failed...", "error");
		}
	};

	const handleCopySlackOption = async () => {
		const [htmlString, message] = convertFieldsToText(latestLoad, COPY_FIELDS, true, true);
		const clipboardItem = new clipboard.ClipboardItem({
			"text/html": new Blob([htmlString], { type: "text/html" }),
			"text/plain": new Blob([message], { type: "text/plain" }),
		});
		copyToClipboardOrSendNativeMsg(message, clipboardItem);
	};

	const btnCopy = () => {
		return {
			onClick: () => {
				handleCopySmsOption();
			},
			title: "Copy",
		};
	};

	const btnCopyAdv = () => {
		return {
			onSelect: (copyOption) => {
				if (copyOption === "Copy Details for Slack") handleCopySlackOption();
				if (copyOption === "Copy Details for SMS") handleCopySmsOption();
			},
			options: ["Copy Details for Slack", "Copy Details for SMS"],
			title: "Copy",
		};
	};

	const btnCallBroker = () => {
		return {
			onClick: () => {
				if (!latestLoad?.contact) {
					return snackbarMsg(ERROR_CALL_BROKER, "error", { autoHideDuration: 5000 });
				}
				window.open("tel:" + latestLoad?.contact, "_self");
			},
			icon: "phone",
			title: "Call Broker",
		};
	};

	const btnSend = () => {
		return {
			onClick: () => {
				executeAction("SHARE");
			},
			icon: "send",
			title: "Share in Dispatching Channel",
		};
	};

	const btnShare = () => {
		return {
			options: RATE_OPTIONS,
			onSelect: (shareOption) => onClickPin("LOAD_SUGGEST_VIEW", "SHARED", hideRate(shareOption)),
			title: "Share",
		};
	};

	const btnTruckPlan = () => {
		return {
			onClick: () => {
				dispatch(
					openFormDialog({
						viewId: "TRUCK_PLAN",
						dataIds: dataIds,
					})
				);
			},
			icon: "event",
			title: "Truck Plan",
		};
	};

	const btnBrain = () => {
		return {
			onClick: () => {
				dispatch(
					openFormDialog({
						viewId: "STRATEGY_VISUALIZER",
						dataIds: {
							...dataIds,
							entryPoint: "load",
						},
					})
				);
			},
			icon: "account_tree",
			title: "Strategy",
		};
	};

	const btnPin = () => {
		return {
			onClick: () => onClickPin("NO_FORM", "LIKED"),
			icon: "bookmark_border",
			title: "Bookmark Load",
		};
	};

	const btnCovered = () => {
		return {
			onClick: () => {
				setDialog("COVERED");
			},
			icon: "event_busy",
			title: "Mark As Covered",
		};
	};

	const btns = {};

	const hasMessagingPermission = roleRestrictions.permission_messaging_on.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_messaging_on: true })
		: true;

	if (isUserExternal && !isUserDriver) {
		btns.copy = btnCopy();
		if (latestLoad?.contact) btns.callBroker = btnCallBroker();
	}

	if (isUserExternal && hasMessagingPermission) {
		btns.send = btnSend();
	}

	if (latestLoad?.fullLoadRecord) {
		btns.share = btnShare();
	}

	if (!isUserExternal) {
		btns.share = btnShare();
		if (!smallScreen) btns.copy = btnCopyAdv();
	}

	if (dataIds?.truckId && !dataIds?.truckId.includes("ANY_") && !window?.ReactNativeWebView) {
		btns.truckplan = btnTruckPlan();
		if (!isUserDriver) btns.pin = btnPin();
		if (!smallScreen) btns.brain = btnBrain();
	}

	btns.covered = btnCovered();

	return btns;
};

const runAction = async (
	actionType,
	setConfirmDialog,
	dispatch,
	setProgress,
	load,
	{ truckId, loadId, userId, searchId },
	snackbar,
	setSaving,
	ignoreWarnings
) => {
	const loadToUse = { ...load, truck: truckId };
	setConfirmDialog({ message: null, prevMessage: null, flag: false, action: null });

	if (actionType === "EXTERNAL_BOOK") {
		setProgress({
			flag: true,
			message:
				"You are being redirected to an external page. Once you book the load, please upload your RateCon on SmartHop",
		});

		setTimeout(() => {
			const reactNative = window?.ReactNativeWebView ?? false;
			if (reactNative) {
				window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "EXTERNAL_LINK", url: loadToUse?.url }));
			} else {
				window.open(loadToUse?.url);
			}
			setProgress({ flag: false, message: null });
		}, 5000);

		return;
	}
	if (actionType === "COVERED") {
		updateLoad(searchId, loadId, { deleted: true });
	}
	if (actionType === "TRIP" || actionType === "BID" || actionType === "BOOK") {
		updateLoad(searchId, loadId, { actionInProgress: true, actionType });
	}

	setSaving?.(true);
	try {
		const response = await executeLoadAction(userId, searchId, loadId, loadToUse, actionType, "SEARCH", ignoreWarnings);
		setSaving?.(false);

		const message = handledActionMessage(actionType);
		showSnackbar(snackbar, message, "success");

		dispatch(incrementDataRevision({ event: "searchResultsChange" + searchId }));
		dispatch(incrementDataRevision({ event: "searchSummaryRevision" }));

		//Not all book now are sync, so by now only let the create trip to mark the load as covered
		if (actionType === "TRIP") {
			if (response.tripId) {
				updateLoad(searchId, loadId, { actionInProgress: false, deleted: true, covered_by_trip: response.tripId });
			} else {
				dispatch(incrementDataRevision({ event: "transactionUpdateRevision" }));
				updateLoad(searchId, loadId, { actionInProgress: false, deleted: true });
			}
		}
		if (actionType === "BID" || actionType === "BOOK") {
			updateLoad(searchId, loadId, { actionInProgress: false });
		}
	} catch (err) {
		const tripWarnings = err?.errors?.[0]?.metadata?.warnings;
		setSaving?.(false);
		updateLoad(searchId, loadId, { actionInProgress: false });
		console.log("[LoadSearchSummary] Error: " + err.errors?.[0]?.message);
		if (tripWarnings?.length) {
			throw err;
		} else {
			showErrorSnackbar(snackbar, err);
		}
	}
};

const getProfitData = (l, truckData) => {
	const dataLoad = {
		miles: l?.miles,
		emptymiles: l?.distance_to_origin,
		stops: l?.stopsNumber > 2 ? l?.stopsNumber - 2 : 0,
		rate: l?.price,
		delivery_date: l?.dropready,
		pickup_date: l?.ready,
	};
	const data = { ...truckData, load: dataLoad };

	return data;
};

const updateLoad = (searchId, loadId, data) => {
	//V3 update
	if (isEnabled("ENABLE_SEARCH_V3")) {
		store.dispatch(updateLoadData({ loadId, searchId, value: data }));
	}
	//V2 update
	store.dispatch(setLoad({ ...data, tripid: loadId }));
};

const onLocationChange = (locationChanges, load, { loadId, truckId, userId, searchId }, dispatch) => {
	updateLoad(searchId, loadId, locationChanges);

	const loadUpdate = {
		...load,
		...locationChanges,
		truck: truckId,
	};

	getDistanceMaps(
		loadUpdate.origin,
		loadUpdate.destination,
		loadUpdate.locations?.map((item) => item.location),
		loadUpdate.truckOrigin
	).then((res) => {
		locationChanges.miles = res.totalMiles;
		locationChanges.emptyMiles = loadUpdate.truckOrigin ? res?.breakdown?.[loadUpdate.truckOrigin] : 0;
		locationChanges.originMiles = res?.breakdown?.[locationChanges.origin];
		locationChanges.locations = locationChanges.locations.map((l) => {
			const updated = { ...l, miles: res?.breakdown?.[l.location] };
			return updated;
		});
		updateLoad(searchId, loadId, locationChanges);
	});

	executeLoadAction(userId, searchId, loadId, loadUpdate, "RECALCULATE", "SEARCH").then((res) => {
		updateLoad(searchId, loadId, {
			est_price: res?.load?.est_price,
			min_price: res?.load?.min_price,
			base_price: res?.load?.base_price,
		});
		dispatch(incrementDataRevision({ event: "searchResultsChange" + searchId }));
	});
};

export { updateLoad, runAction, getButtons, getProfitData, onLocationChange };
